import BaseBean from "@/utils/BaseBean";

export interface ICpmtjListDataObj {
    utilInst:CpmtjListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class CpmtjListUtil extends BaseBean{
    public dataObj:ICpmtjListDataObj

    constructor(proxy:any,dataObj:ICpmtjListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}